import React from 'react';

function About() {
  return (
    <div id="about">
      <p className="lead text-center w-75">
        <span className="font-weight-bold">COVID-19 update: We are open and also available on Deliveroo and UberEats</span><br/><br/>
        Started up in the heart of South East London in 1992 by Eric, this friendly and respected traditional fish and chip shop has taken Deptford by a storm.<br/><br/>
        With massive portions, made fresh, at cheap prices and a forever increasing menu variety, come check out Welcome Inn Fish Bar for the best authentic chippy experience!
      </p>
    </div>
  );
}

export default About;
