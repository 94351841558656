import React from 'react';

function UberEats() {
  return (
    <div id="uberEats" className="text-center">
      <h2 className="display-4">Find us on UberEats!</h2>
      <p className="lead">
        We are now available on UberEats!<br/>
        <a className="btn btn-primary btn-lg btn mt-4" href="https://www.ubereats.com/gb/london/food-delivery/welcome-inn-fish-bar/UV-Ye9JuROCqQsGdGGnOAw">Click here to order online.</a>
      </p>
    </div>
  );
}

export default UberEats;
