import React from 'react';

function Menu() {
  return (
    <div id="menu">
      <div className="justify-content-around">
        <h2 className="display-4 text-center w-100 pt-4">Menu</h2>
        <div>
          <ul className="list-group p-4">
            <li className="list-group-item lead">Fish</li>
            <li className="list-group-item"><span className="item">Cod</span></li>
            <li className="list-group-item"><span className="item">Scampi (12pcs)</span></li>
            <li className="list-group-item"><span className="item">8 mini cod, chips and sweet chilli sauce</span></li>
            <li className="list-group-item"><span className="item">Cod roe</span></li>
            <li className="list-group-item"><span className="item">Fish cake</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Chips</li>
            <li className="list-group-item"><span className="item">Portion of chips</span></li>
            <li className="list-group-item"><span className="item">Large portion of chips</span></li>
            <li className="list-group-item"><span className="item">Chip roll</span></li>
            <li className="list-group-item"><span className="item">Butter roll</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Burgers</li>
            <li className="list-group-item"><span className="item">Beef burger with salad</span></li>
            <li className="list-group-item"><span className="item">Double beef burger with salad</span></li>
            <li className="list-group-item"><span className="item">Double beef burger with cheese and salad</span></li>
            <li className="list-group-item"><span className="item">Cheeseburger with salad</span></li>
            <li className="list-group-item"><span className="item">Chicken fillet burger with salad</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Chicken</li>
            <li className="list-group-item"><span className="item">1pc chicken</span></li>
            <li className="list-group-item"><span className="item">2pc chicken</span></li>
            <li className="list-group-item"><span className="item">1pc chicken and chips</span></li>
            <li className="list-group-item"><span className="item">2pc chicken and chips</span></li>
            <li className="list-group-item"><span className="item">1 whole hot wing</span></li>
            <li className="list-group-item"><span className="item">2 whole hot wing</span></li>
            <li className="list-group-item"><span className="item">2 whole hot wings and chips</span></li>
            <li className="list-group-item"><span className="item">4 whole hot wings and chips</span></li>
            <li className="list-group-item"><span className="item">5 pc chicken nuggets</span></li>
            <li className="list-group-item"><span className="item">5 pc chicken nuggets and chips</span></li>
            <li className="list-group-item"><span className="item">10 pc chicken nuggets</span></li>
            <li className="list-group-item"><span className="item">10 pc chicken nuggets and chips</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Burger Meals</li>
            <li className="list-group-item"><span className="item">Beef burger with salad, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Double beef burger with salad, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Cheeseburger with salad, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Double beef burger with cheese, salad, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Chicken fillet burger with salad, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Vegetarian burger with salad, chips, and a drink</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Peter's Pie</li>
            <li className="list-group-item"><span className="item">Peter's steak and kidney pie</span></li>
            <li className="list-group-item"><span className="item">Peter's minced beef and onion pie</span></li>
            <li className="list-group-item"><span className="item">Peter's chicken mushroom pie</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Jamaican Patties</li>
            <li className="list-group-item"><span className="item">Beef patty</span></li>
            <li className="list-group-item"><span className="item">Chicken patty</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Sides</li>
            <li className="list-group-item"><span className="item">Jumbo sausage</span></li>
            <li className="list-group-item"><span className="item">Batter sausage</span></li>
            <li className="list-group-item"><span className="item">Saveloy</span></li>
            <li className="list-group-item"><span className="item">Curry roll</span></li>
            <li className="list-group-item"><span className="item">10pc Onion rings</span></li>
            <li className="list-group-item"><span className="item">Mushy peas</span></li>
            <li className="list-group-item"><span className="item">Pickled onion</span></li>
            <li className="list-group-item"><span className="item">Pickled gherkin</span></li>
            <li className="list-group-item"><span className="item">Curry sausage</span></li>
            <li className="list-group-item"><span className="item">Non-brewed condiment</span></li>
            <li className="list-group-item"><span className="item">Bottle onion vinegar</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Special</li>
            <li className="list-group-item"><span className="item">Chips in curry sausage</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Kid's Meals</li>
            <li className="list-group-item"><span className="item">4pcs Nuggets, chips, and a drink</span></li>
            <li className="list-group-item"><span className="item">Burger, chips, and a drink</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Vegetarian</li>
            <li className="list-group-item"><span className="item">10 mini vegetable spring rolls with sweet chilli sauce</span></li>
            <li className="list-group-item"><span className="item">Jamaican vegetable patty</span></li>
            <li className="list-group-item"><span className="item">Vegetarian burger with salad</span></li>
          </ul>

          <ul className="list-group p-4">
            <li className="list-group-item lead">Drinks</li>
            <li className="list-group-item"><span className="item">Coke/Diet coke/1.25L</span></li>
            <li className="list-group-item"><span className="item">Pepsi</span></li>
            <li className="list-group-item"><span className="item">Tango orange/apple</span></li>
            <li className="list-group-item"><span className="item">Jamaican ginger beer</span></li>
            <li className="list-group-item"><span className="item">Jamaican cream soda</span></li>
            <li className="list-group-item"><span className="item">7UP</span></li>
            <li className="list-group-item"><span className="item">Lilt</span></li>
            <li className="list-group-item"><span className="item">Dr. Pepper</span></li>
            <li className="list-group-item"><span className="item">Ribena</span></li>
            <li className="list-group-item"><span className="item">Water</span></li>
          </ul>

          <ul className="filling-empty-space-childs"></ul>
          <ul className="filling-empty-space-childs"></ul>
          <ul className="filling-empty-space-childs"></ul>
        </div>
        </div>
    </div>
  );
}

export default Menu;
