import React from 'react';

function Deliveroo() {
  return (
    <div id="deliveroo" className="text-center">
      <h2 className="display-4">Find us on Deliveroo!</h2>
      <p className="lead">
        We are now available on Deliveroo!<br/>
        <a className="btn btn-primary btn-lg btn mt-4" href="https://deliveroo.co.uk/menu/london/surrey-quays/welcome-inn-fish-bar-ltd">Click here to order online.</a>
      </p>
    </div>
  );
}

export default Deliveroo;
